import { Node } from 'tiptap'
import { toggleBlockType, textblockTypeInputRule } from 'tiptap-commands'

export default class CodeBlock extends Node {
  get name() {
    return 'code_block'
  }

  get schema() {
    return {
      group: 'block',
      content: 'text*',
      marks: '',
      code: true,
      draggable: false,

      parseDOM: [
        { tag: 'pre', preserveWhitespace: 'full' },
      ],

      toDOM: () => [
        'pre',
        [
          'code',
          0,
        ],
      ],
    }
  }

  commands({ type, schema }) {
    return () => toggleBlockType(type, schema.nodes.paragraph)
  }

  keys({ type, schema }) {
    return {
      'Shift-Mod-,': toggleBlockType(type, schema.nodes.paragraph),
    }
  }

  inputRules({ type }) {
    return [
      textblockTypeInputRule(/^```$/, type),
    ]
  }
}
