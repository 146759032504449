import { Mark } from 'tiptap'
// noinspection ES6CheckImport
import { markInputRule, toggleMark } from 'tiptap-commands'
import LinkComponent from '@/components/ContentEditor/Link'

export default class Link extends Mark {
  get name() {
    return 'link'
  }

  get schema() {
    return {
      inclusive: false,
      excludes: '_',

      attrs: {
        href: { default: '' },
      },

      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs: dom => ({
            href: dom.getAttribute('href')
          }),
        },
      ],

      toDOM: node => [
        'a',
        {
          href: node.attrs.href,
          rel: 'noopener',
          target: node.attrs.href.startsWith('/') ? undefined : '_blank',
        },
        0
      ],
    }
  }

  get view() {
    return LinkComponent
  }

  commands({ type }) {
    return () => toggleMark(type)
  }

  keys({ type }) {
    return {
      'Mod-k': toggleMark(type),
    }
  }

  inputRules({ type }) {
    return [
      markInputRule(/(?:\[\[)([^[]+)(?:\]\])$/, type),
    ]
  }
}
