<template>
  <figure class="hn-image" :class="{ side: node.attrs.isOnSide }" @click="figureClicked">
    <!--suppress HtmlUnknownTarget -->
    <img v-if="node.attrs.imageUUID" :src="imgSrc" :alt="imgAlt">
    <div v-if="!node.attrs.imageUUID" class="image-upload-status" :class="{ error: errorMessage }">
      <span v-if="!errorMessage && (progress < 1)">Изображение загружается… (нажмите для отмены)</span>
      <span v-if="!errorMessage && (progress >= 1)">Обработка… (нажмите для отмены)</span>
      <span v-if="errorMessage">Ошибка загрузки изображения: {{ errorMessage }}.</span>
      <div v-if="!errorMessage" class="progress-bar" :style="{ '--progress': progress }"/>
    </div>
    <figcaption v-if="node.attrs.imageUUID && node.attrs.caption">
      {{ node.attrs.caption }}
    </figcaption>
  </figure>
</template>

<script>
import axios from 'axios'
require('formdata-polyfill')

export default {
  name: 'CEImage',
  props: ['node', 'updateAttrs', 'view', 'getPos'],

  data() {
    return {
      errorMessage: null,
      progress: 0,
      cancelTokenSource: null,
      schema: [
        {
          key: 'caption',
          type: 'text',
          placeholder: 'Подпись к изображению',
        },
        {
          key: 'isOnSide',
          type: 'segments',
          values: [
            {
              value: false,
              displayName: 'Во всю ширину',
            },
            {
              value: true,
              displayName: 'Сбоку',
            },
          ],
        },
      ],
    }
  },

  mounted() {
    if(!this.node.attrs.imageUUID) {
      this.uploadFile()
    }
  },

  methods: {
    uploadFile() {
      if(!this.$root.auth) {
        this.errorMessage = 'Вы должны войти, чтобы загружать изображения'
        return
      }

      const file = this.node.attrs.file
      if(!(file instanceof File)) {
        this.errorMessage = 'Неизвестная ошибка. Попробуйте снова'
        return
      }

      const formData = new FormData()
      formData.append('file', file, 'imagefile')
      const headers = {
        'Content-Type': 'multipart/form-data',
      }
      headers['X-HN-Token'] = this.$root.auth.token

      const cancelToken = axios.CancelToken
      this.cancelTokenSource = cancelToken.source()
      axios.post(
        `${this.$root.globalParams.developerBaseURI}/upload-content-image`,
        formData,
        {
          headers,
          cancelToken: this.cancelTokenSource.token,
          onUploadProgress: this.uploadProgressChanged
        },
      )
        .then(response => {
          this.cancelTokenSource = null
          if(response.data.error) {
            console.error(`Ошибка загрузки изображения: ${response.data.message}`)
            this.errorMessage = response.data.message
            return
          }

          this.updateAttrs({
            file: null,
            imageUUID: response.data.result.uuid,
          })
        })
        .catch(error => {
          this.cancelTokenSource = null
          console.error(`Ошибка загрузки изображения: ${error}`)
          console.info(error.response)
          this.errorMessage = 'сетевая ошибка. Попробуйте снова'
        })
    },

    uploadProgressChanged(event) {
      // noinspection UnnecessaryLocalVariableJS
      const progress = event.loaded / event.total
      this.progress = progress
    },

    figureClicked(event) {
      event.preventDefault()
      event.stopPropagation()

      if(!this.node.attrs.imageUUID) {
        this.remove()
        return
      }

      this.showAttributeEditor()
    },

    showAttributeEditor() {
      this.$root.attributerAttrs.removeSubstring = 'изображение'
      this.$root.attributerAttrs.schema = this.schema
      this.$root.attributerAttrs.data = {
        caption: this.node.attrs.caption ? this.node.attrs.caption : '',
        isOnSide: this.node.attrs.isOnSide,
      }
      this.$root.attributerAttrs.onSave = this.save
      this.$root.attributerAttrs.onRemove = this.remove

      this.$root.attributerAttrs.isShown = true
    },

    save() {
      const caption = this.$root.attributerAttrs.data.caption ? this.$root.attributerAttrs.data.caption : null
      const isOnSide = this.$root.attributerAttrs.data.isOnSide
      this.updateAttrs({
        caption,
        isOnSide
      })
      return true
    },

    remove() {
      if(this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }

      const nodePos = this.getPos()
      const nodeSize = this.node.nodeSize
      const nodeEnd = nodePos + nodeSize
      const transaction = this.view.state.tr
      transaction.delete(nodePos, nodeEnd)
      this.view.dispatch(transaction)
      return true
    },
  },

  computed: {
    imgSrc: {
      get() {
        const relativePath = '/user-content/content/'
        const uuid = this.node.attrs.imageUUID
        const extension = 'jpg'
        const url = `${this.$root.globalParams.developerBaseURI}${relativePath}${uuid}/orig.${extension}`
        return url
      }
    },

    imgAlt: {
      get() {
        if(!this.node.attrs.caption) {
          return undefined
        }

        return this.node.attrs.caption
      }
    }
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';

  figure.hn-image {
    cursor: pointer;

    .image-upload-status {
      width: 200px;
      height: 150px;
      background-color: @color-block-background;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 25px;
      box-sizing: border-box;
      text-align: center;
      position: relative;

      &.error {
        background-color: @color-error;

        span {
          color: @color-block-background;
          font-weight: bold;
        }
      }

      .progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: @color-accent;
        transform: scaleX(var(--progress));
      }
    }
  }
</style>
