import { Node } from 'tiptap'
import { splitListItem } from 'tiptap-commands'

export default class ListItem extends Node {
  get name() {
    return 'list_item'
  }

  get schema() {
    return {
      content: 'paragraph',
      defining: true,

      parseDOM: [
        { tag: 'li' },
      ],

      toDOM: () => [
        'li',
        0,
      ],
    }
  }

  keys({ type }) {
    return {
      Enter: splitListItem(type),
    }
  }
}
