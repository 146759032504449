<template>
  <a :href="node.attrs.href" @click="showAttributeEditor">
    <slot/>
  </a>
</template>

<script>
import { getMarkRange } from 'tiptap-utils'

export default {
  name: 'Link',
  props: ['node', 'updateAttrs', 'view'],

  data() {
    return {
      schema: [
        {
          key: 'href',
          type: 'text',
          placeholder: 'Адрес ссылки',
        },
      ],
    }
  },

  methods: {
    showAttributeEditor(event) {
      event.preventDefault()
      event.stopPropagation()

      this.$root.attributerAttrs.removeSubstring = 'ссылку'
      this.$root.attributerAttrs.schema = this.schema
      this.$root.attributerAttrs.data = { href: this.node.attrs.href }
      this.$root.attributerAttrs.onSave = this.save
      this.$root.attributerAttrs.onRemove = this.remove

      this.$root.attributerAttrs.isShown = true
    },

    save() {
      const href = this.$root.attributerAttrs.data.href.trim()
      const hrefIsValid = href.startsWith('https://') || href.startsWith('http://') || href.startsWith('/')

      if(!hrefIsValid) {
        alert('Ссылка некорректна.\nСсылки должны начинаться с https:// или http://\nЕсли ссылка внутренняя, она также может начинаться с /')
        return false
      }

      this.updateAttrs({
        href: this.$root.attributerAttrs.data.href
      })
      return true
    },

    remove() {
      const { tr, selection } = this.view.state
      let { from, to } = selection
      const { $from, empty } = selection

      if(empty) {
        const range = getMarkRange($from, this.node.type)

        from = range.from
        to = range.to
      }

      tr.removeMark(from, to, this.node.type)
      this.view.dispatch(tr)

      return true
    },
  },
}
</script>

<style lang="less" scoped>
  a {
    cursor: pointer;
  }
</style>
