import { Mark } from 'tiptap'
// noinspection ES6CheckImport
import { markInputRule, toggleMark } from 'tiptap-commands'

export default class Italic extends Mark {
  get name() {
    return 'italic'
  }

  get schema() {
    return {
      parseDOM: [
        { tag: 'em' },
        { tag: 'i' },
      ],

      toDOM: () => [
        'em',
        0
      ],
    }
  }

  commands({ type }) {
    return () => toggleMark(type)
  }

  keys({ type }) {
    return {
      'Mod-i': toggleMark(type),
    }
  }

  inputRules({ type }) {
    return [
      markInputRule(/(?:\/\/)([^/]+)(?:\/\/)$/, type),
    ]
  }
}
