import { Node } from 'tiptap'
import { toggleList, wrappingInputRule } from 'tiptap-commands'

export default class OrderedList extends Node {
  get name() {
    return 'ordered_list'
  }

  get schema() {
    return {
      group: 'block',
      content: 'list_item+',

      parseDOM: [
        { tag: 'ol' },
      ],

      toDOM: () => [
        'ol',
        0,
      ],
    }
  }

  commands({ type, schema }) {
    return () => toggleList(type, schema.nodes.list_item)
  }

  keys({ type, schema }) {
    return {
      'Shift-Mod-o': toggleList(type, schema.nodes.list_item),
    }
  }

  inputRules({ type }) {
    return [
      wrappingInputRule(/^1\.\s$/, type),
    ]
  }
}
