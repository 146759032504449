<template>
  <figure class="hn-video" @click="showAttributeEditor">
    <iframe v-if="src" :src="src" />
    <div class="overlay" />
    <div class="info-block add-video" v-if="this.node.attrs.identifier === null">
      Нажмите на блок, чтобы добавить видео.
    </div>
    <div class="info-block link-error" v-if="this.node.attrs.identifier === ''">
      Невозможно извлечь видео из ссылки.<br/>
      Попробуйте другую ссылку.
    </div>
  </figure>
</template>

<script>
export default {
  name: 'Video',
  props: ['node', 'updateAttrs', 'getPos', 'view'],

  data() {
    return {
      schema: [
        {
          key: 'videoURL',
          type: 'text',
          placeholder: 'Ссылка на видео',
        },
      ],
    }
  },

  computed: {
    src: {
      get() {
        if(!this.node.attrs.identifier) {
          return null
        }

        const baseURI = 'https://www.youtube.com/embed/'
        const url = `${baseURI}${this.node.attrs.identifier}`
        return url
      },
    },
  },

  methods: {
    showAttributeEditor(event) {
      event.preventDefault()
      event.stopPropagation()

      this.$root.attributerAttrs.removeSubstring = 'видео'
      this.$root.attributerAttrs.schema = this.schema
      const videoURL = this.node.attrs.identifier ? `https://youtu.be/${this.node.attrs.identifier}` : ''
      this.$root.attributerAttrs.data = { videoURL }
      this.$root.attributerAttrs.onSave = this.save
      this.$root.attributerAttrs.onRemove = this.remove

      this.$root.attributerAttrs.isShown = true
    },

    save() {
      const videoURL = this.$root.attributerAttrs.data.videoURL.trim()
      if(videoURL.length < 1) {
        alert('Вы не указали ссылку на видео.')
        return false
      }

      const videoID = this.getYouTubeVideoID(videoURL)
      if(videoID === null || videoID === '') {
        alert('Невозможно распознать ссылку на видео.\nПопробуйте другую ссылку.')
        return false
      }

      const platform = 'youtube'

      this.updateAttrs({
        platform,
        identifier: videoID,
      })
      return true
    },

    remove() {
      const nodePos = this.getPos()
      const nodeSize = this.node.nodeSize
      const nodeEnd = nodePos + nodeSize
      const transaction = this.view.state.tr
      transaction.delete(nodePos, nodeEnd)
      this.view.dispatch(transaction)
      return true
    },

    getYouTubeVideoID(url) {
      if(url === null) {
        return null
      }

      let id = ''
      const splitUrl = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      if(splitUrl[2] !== undefined) {
        id = splitUrl[2].split(/[^0-9a-z_-]/i)
        id = id[0]
      } else {
        id = splitUrl
      }

      if(typeof id !== 'string') {
        return ''
      }

      if(id.length !== 11) {
        return ''
      }

      return id
    },
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';

  figure.hn-video {
    cursor: pointer;
    position: relative;
  }

  iframe {
    pointer-events: none;
    z-index: 1;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .info-block {
    width: var(--iframe-width);
    height: var(--iframe-height);
    background-color: @color-block-background;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.link-error {
      background-color: @color-error;
      font-weight: bold;
      color: @color-block-background;
    }
  }
</style>
