import { Node, Plugin } from 'tiptap'
import ImageComponent from '@/components/ContentEditor/Image'

function getURLForUUID(uuid) {
  const developerBaseURI = (process.env.NODE_ENV === 'production') ? '' : 'https://helperia.ru'
  const relativePath = '/user-content/content/'
  const extension = 'jpg'

  const result = `${developerBaseURI}${relativePath}${uuid}/orig.${extension}`
  return result
}

export default class Image extends Node {
  get name() {
    return 'image'
  }

  get schema() {
    return {
      group: 'block',
      draggable: true,

      attrs: {
        imageUUID: { default: null },
        caption: { default: null },
        isOnSide: { default: false },
        file: { default: null },
      },

      parseDOM: [
        {
          tag: 'figure.hn-image',
          getAttrs(dom) {
            if(!('hnUuid' in dom.dataset)) {
              return false
            }

            const imageUUID = dom.dataset.hnUuid
            const caption = ('hnCaption' in dom.dataset) ? dom.dataset.hnCaption : null
            const isOnSide = dom.classList.contains('side')
            const attrs = {
              imageUUID,
              caption,
              isOnSide,
            }
            return attrs
          },
        },
      ],

      toDOM: node => {
        const imgAttributes = {
          src: getURLForUUID(node.attrs.imageUUID),
        }
        if(node.attrs.caption) {
          imgAttributes.alt = node.attrs.caption
        }
        const img = [
          'img',
          imgAttributes
        ]

        const figureAttributes = {
          class: node.attrs.isOnSide ? 'hn-image side' : 'hn-image',
          'data-hn-uuid': node.attrs.imageUUID,
        }
        if(node.attrs.caption) {
          figureAttributes['data-hn-caption'] = node.attrs.caption
        }

        const figure = [
          'figure',
          figureAttributes,
          img,
        ]

        if(node.attrs.caption) {
          const figcaption = [
            'figcaption',
            node.attrs.caption,
          ]
          figure.push(figcaption)
        }

        return figure
      },
    }
  }

  get view() {
    return ImageComponent
  }

  get plugins() {
    // noinspection JSUnusedGlobalSymbols
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            drop(view, event) {
              const hasFiles = event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length
              if(!hasFiles) {
                return
              }

              event.preventDefault()

              const files = Array.from(event.dataTransfer.files)
              const allowedMIMETypes = [
                'image/jpeg',
                'image/png',
              ]
              const allowedFiles = files.filter(file => {
                const isAllowedFile = allowedMIMETypes.includes(file.type)
                return isAllowedFile
              })
              if(allowedFiles.length < 1) {
                alert('Поддерживаются только изображения следующих форматов:\n- JPEG (.jpg, .jpeg, .jfif)\n- PNG (.png)')
                return
              }

              const maxFileSize = 5000000 // 5 Megabytes
              const appropriatelySizedFiles = files.filter(file => {
                const isAppropriatelySized = file.size <= maxFileSize
                return isAppropriatelySized
              })
              if(appropriatelySizedFiles.length < 1) {
                alert('Максимальный размер файла — 5 МБ.')
                return
              }

              const filesToUpload =
                (appropriatelySizedFiles.length > 5)
                  ? appropriatelySizedFiles.slice(0, 5)
                  : appropriatelySizedFiles

              const { schema } = view.state
              const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY })
              filesToUpload.forEach(async image => {
                const node = schema.nodes.image.create({
                  file: image,
                })
                const transaction = view.state.tr.insert(coordinates.pos, node)
                view.dispatch(transaction)
              })
            },
          },
        },
      }),
    ]
  }
}
