import { Mark } from 'tiptap'
// noinspection ES6CheckImport
import { toggleMark, markInputRule } from 'tiptap-commands'

export default class Bold extends Mark {
  get name() {
    return 'bold'
  }

  get schema() {
    return {
      parseDOM: [
        { tag: 'strong' },
        { tag: 'b' },
      ],

      toDOM: () => [
        'strong',
        0
      ],
    }
  }

  commands({ type }) {
    return () => toggleMark(type)
  }

  keys({ type }) {
    return {
      'Mod-b': toggleMark(type),
    }
  }

  inputRules({ type }) {
    return [
      markInputRule(/(?:\*\*)([^*]+)(?:\*\*)$/, type),
    ]
  }
}
