import { Node } from 'tiptap'
import { toggleWrap, wrappingInputRule } from 'tiptap-commands'

export default class Blockquote extends Node {
  get name() {
    return 'blockquote'
  }

  get schema() {
    return {
      group: 'block',
      content: 'paragraph+',

      parseDOM: [
        { tag: 'blockquote' },
      ],

      toDOM: () => [
        'blockquote',
        0,
      ],
    }
  }

  commands({ type }) {
    return () => toggleWrap(type)
  }

  keys({ type }) {
    return {
      'Mod-\'': toggleWrap(type),
    }
  }

  inputRules({ type }) {
    return [
      wrappingInputRule(/^>\s$/, type),
    ]
  }
}
