import { Node } from 'tiptap'
import VideoComponent from '@/components/ContentEditor/Video'

export default class Video extends Node {
  get name() {
    return 'video'
  }

  get schema() {
    return {
      group: 'block',
      draggable: true,

      attrs: {
        platform: { default: 'youtube' },
        identifier: { default: null },
      },

      parseDOM: [
        {
          tag: 'figure.hn-video',
          getAttrs(dom) {
            if(!('hnPlatform' in dom.dataset)) {
              return false
            }

            const platform = dom.dataset.hnPlatform
            const identifier = ('hnIdentifier' in dom.dataset) ? dom.dataset.hnIdentifier : null
            const attrs = {
              platform,
              identifier,
            }
            return attrs
          },
        },
      ],

      toDOM: node => {
        const platformBaseURI = 'https://www.youtube.com/embed/'
        const src = node.attrs.identifier ? `${platformBaseURI}${node.attrs.identifier}` : null

        const figureAttributes = {
          class: 'hn-video',
          'data-hn-platform': node.attrs.platform,
        }
        if(node.attrs.identifier) {
          figureAttributes['data-hn-identifier'] = node.attrs.identifier
        }
        const figure = [
          'figure',
          figureAttributes,
        ]

        if(src) {
          const iframe = [
            'iframe',
            {
              src,
              allow: 'encrypted-media; picture-in-picture',
              allowfullscreen: '',
            },
          ]
          figure.push(iframe)
        }

        return figure
      },
    }
  }

  get view() {
    return VideoComponent
  }

  commands({ type }) {
    return () => (state, dispatch) => dispatch(state.tr.replaceSelectionWith(type.create()))
  }

  keys({ type }) {
    return {
      'Shift-Mod-v': (state, dispatch) => dispatch(state.tr.replaceSelectionWith(type.create())),
    }
  }
}
