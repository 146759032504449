import { Node } from 'tiptap'
import { setBlockType, textblockTypeInputRule, toggleBlockType } from 'tiptap-commands'

export default class Heading extends Node {
  get name() {
    return 'heading'
  }

  get defaultOptions() {
    return {
      levels: [1, 2, 3],
    }
  }

  get schema() {
    return {
      group: 'block',
      content: 'text*',
      marks: '',

      attrs: {
        level: { default: 1 },
      },

      parseDOM: [
        {
          tag: 'h2',
          attrs: { level: 1 },
        },
        {
          tag: 'h3',
          attrs: { level: 2 },
        },
        {
          tag: 'h4',
          attrs: { level: 3 },
        },
      ],

      toDOM: node => [
        `h${node.attrs.level + 1}`,
        0
      ],
    }
  }

  commands({ type, schema }) {
    return attrs => toggleBlockType(type, schema.nodes.paragraph, attrs)
  }

  keys({ type }) {
    return {
      'Shift-Mod-1': setBlockType(type, { level: 1 }),
      'Shift-Mod-2': setBlockType(type, { level: 2 }),
      'Shift-Mod-3': setBlockType(type, { level: 3 }),
    }
  }

  inputRules({ type }) {
    return [
      textblockTypeInputRule(/^#\s$/, type, { level: 1 }),
      textblockTypeInputRule(/^##\s$/, type, { level: 2 }),
      textblockTypeInputRule(/^###\s$/, type, { level: 3 }),
    ]
  }
}
